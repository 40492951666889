var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-loading", { attrs: { active: true } }, [
    _vm.error
      ? _c("div", { staticClass: "column is-vcentered has-text-centered" }, [
          _c(
            "div",
            { staticClass: "content is-z-100" },
            [
              _c("b-icon", {
                attrs: {
                  icon: "exclamation",
                  type: "is-danger",
                  "custom-size": "10x"
                }
              }),
              _c("p", { staticClass: "is-title is-danger" }, [
                _vm._v(_vm._s(_vm.errorDescription))
              ]),
              _c(
                "b-button",
                {
                  attrs: { type: "is-danger", size: "is-small" },
                  on: { click: _vm.closeWindow }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }