import { __decorate, __extends } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
var AutodeskLoginPopup = (function (_super) {
    __extends(AutodeskLoginPopup, _super);
    function AutodeskLoginPopup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = null;
        _this.error = null;
        _this.errorDescription = null;
        return _this;
    }
    AutodeskLoginPopup.prototype.mounted = function () {
        var _this = this;
        this.code = this.$route.query.code;
        this.state = this.$route.query.state;
        this.error = this.$route.query.error;
        this.errorDescription = this.$route.query.error_description;
        if (this.error) {
        }
        else if (!this.code) {
            window.location.href = this.getFunctionURL('forgeRedirect');
        }
        else {
            var tokenFunctionURL = this.getFunctionURL('token');
            this.$jsonp(tokenFunctionURL, { code: this.code })
                .then(function (data) {
                _this.$auth.signInWithCustomToken(data.token).then(function () {
                    window.close();
                });
            })
                .catch(function (err) {
                console.error('Something terrible happened', err);
                _this.error = err.name;
                _this.errorDescription = err.message;
            });
        }
    };
    AutodeskLoginPopup.prototype.closeWindow = function () {
        window.close();
    };
    AutodeskLoginPopup.prototype.getFunctionURL = function (name) {
        return process.env.VUE_APP_FIREBASE_FUNCTIONS_URL + name;
    };
    AutodeskLoginPopup = __decorate([
        Component
    ], AutodeskLoginPopup);
    return AutodeskLoginPopup;
}(Vue));
export default AutodeskLoginPopup;
